<template>
  <div>
    <div class="inner_head">
      <img class="head_img" src="../../statics/images/company/xinli_adv.jpg" alt="">
    </div>
    <div class="current_menu">
      <div class="content">
        <div class="page_indicate">
          <div class="img" />
          <span>走进新力<span> > </span>{{ menuTitle }}</span>
        </div>
        <ul class="page_menu">
          <li
            v-for="(item,index) in menuList"
            :key="index"
            :class="{'active': index === menuActive}"
            @click="menuSelect(item,index)"
          >
            {{ item.name }}
            <div class="menu_block" />
          </li>
        </ul>
      </div>
    </div>
    <div v-for="(item,index) in contentList" :key="index">
      <div v-if="item.flag === '1'" class="develop">
        <div class="home_content">
          <div class="head">
            <div class="left">
              <p class="home_title">{{ item.title }}</p>
              <p class="home_sub">{{ item.shortTitle }}</p>
            </div>
          </div>
          <div v-if="arrowLeft" class="arrow left_arrow" @click="handleLeft" />
          <div v-if="arrowRight" class="arrow right_arrow" @click="hangdleRight" />
          <div class="timeline_module">
            <div class="timeline" :style="{left: timeLeft + 'px'}">
              <div v-for="(cItem,cIndex) in timeLineList" :key="cIndex" class="item">
                <div class="top">
                  <p v-if="cIndex % 2 === 0" class="date">{{ cItem.shortTitle }}</p>
                  <div v-else>
                    <p class="b_title">{{ cItem.title }}</p>
                    <p class="detail">{{ cItem.remark }}</p>
                  </div>
                </div>
                <div class="label">
                  <div class="line" />
                  <div class="point" />
                  <div class="vertical_line" />
                </div>
                <div class="bottom">
                  <p v-if="cIndex % 2 !== 0" class="date">{{ cItem.shortTitle }}</p>
                  <div v-else>
                    <p class="b_title">{{ cItem.title }}</p>
                    <p class="detail">{{ cItem.remark }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="item.flag === '2'" class="culture">
        <div class="home_content">
          <div class="head">
            <div class="left">
              <p class="home_title">{{ item.shortTitle }}</p>
              <p class="home_sub">{{ item.title }}</p>
            </div>
          </div>
          <div class="substance">
            <img src="../../statics/images/company/vision.png" alt="">
            <div class="vision">
              <p class="title">{{ item.children[0].title }}</p>
              <p class="detail">{{ item.children[0].remark }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="item.flag === '5'" class="comp_culture">
        <div class="home_content">
          <img :src="baseUrl + item.iconUrl" alt="">
          <ul>
            <li v-for="(cItem,cIndex) in item.contentItemTreeList" :key="cIndex">
              <p><span class="title">{{ cItem.title }}</span>：{{ cItem.shortTitle }}</p>
              <p>{{ cItem.remark }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="item.flag === '8'" class="project">
        <ul>
          <li v-for="(cItem,cIndex) in item.contentItemTreeList" :key="cIndex">
            <p class="year">{{ cItem.title }}</p>
            <ul class="year_list">
              <li v-for="(pItem,pIndex) in cItem.children" :key="pIndex">
                <p class="month">{{ pItem.title }}</p>
                <p>{{ pItem.remark }}</p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div v-if="item.flag === '9'" class="aptitude">
        <ul>
          <li v-for="(cItem,cIndex) in item.contentItemTreeList" :key="cIndex">
            <p v-if="cItem.title" class="title">{{ cItem.title }}</p>
            <p v-if="cItem.remark" class="sub">{{ cItem.remark }}</p>
            <div>
              <img v-if="cItem.iconUrl" :src="baseUrl + cItem.iconUrl" alt="">
            </div>
            <ul v-if="cItem.children" class="aptitude_d_ul">
              <li v-for="(dItem,dIndex) in cItem.children" :key="dIndex">
                <p v-if="dItem.title" class="title">{{ dItem.title }}</p>
                <p v-if="dItem.remark" class="sub">{{ dItem.remark }}</p>
                <div>
                  <img v-if="dItem.iconUrl" :src="baseUrl + dItem.iconUrl" alt="">
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div v-if="item.flag === '10'" class="architecture">
        <img class="head_img" :src="baseUrl + item.iconUrl" alt="">
      </div>
      <div v-if="item.flag === '11'" class="introduction">
        <div class="home_content">
          <div class="left">
            <p class="home_title">{{ item.shortTitle }}</p>
            <p class="home_sub">{{ item.title }}</p>
            <p class="detail">{{ item.remark }}</p>
            <div class="info">
              <ul>
                <li v-for="(cItem,cIndex) in item.children" :key="cIndex">
                  <div>
                    <p class="num_p">
                      <span class="num">{{ cItem.remark }}</span>
                      {{ cItem.shortTitle }}
                    </p>
                    <p class="sub">{{ cItem.title }}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <img :src="baseUrl + item.iconUrl" alt="">
        </div>
      </div>
      <div v-if="item.flag === '12'" class="comp_clut">
        <p>{{ item.remark }}</p>
      </div>
      <div v-if="item.flag === '13'" class="news">
        <ul v-if="archieveList.length">
          <li v-for="(aItem,aIndex) in archieveList" :key="aIndex" @click="detailArchieve(aItem)">
            <img v-if="aItem.iconUrl" :src="baseUrl + aItem.iconUrl" alt="">
            <img v-else src="../../statics/images/s_logo.png" alt="">
            <div class="info">
              <div class="head">
                <p class="title">{{ aItem.title }}</p>
                <p v-if="aItem.publishDate" class="date">{{ aItem.publishDate.slice(0, 10) }}</p>
              </div>
              <p class="remark">{{ aItem.remark }}</p>
            </div>
          </li>
        </ul>
        <div v-else class="no_info">暂无数据</div>
        <div v-if="archieveList.length" class="pagination_con">
          <el-pagination background layout="prev, pager, next" :current-page="news.currentPage" :total="news.total" @current-change="newsCurrentChange" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMenu, getMainContent, getArchiveListByMenuId } from '@/plugin/api.js'
export default {
  data() {
    return {
      menuList: [],
      menuActive: 0,
      menuTitle: '公司简介',
      timeLeft: 0,
      arrowLeft: false,
      arrowRight: true,
      baseUrl: '/website',
      info: {
        intro: null
      },
      news: {
        total: 0,
        currentPage: 1
      },
      contentList: [],
      archieveList: []
    }
  },
  watch: {
    '$route': {
      handler(newVal) {
        if (newVal) this.routeChange()
      }
    },
    timeLeft: {
      handler(newVal) {
        this.arrowRight = newVal !== -280 * (this.timeLineList.length - 4)
        this.arrowLeft = newVal !== 0
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    newsCurrentChange(number) {
      this.news.currentPage = number
      getArchiveListByMenuId({ tenantId: 'xljr', menuId: 145, pageNum: this.news.currentPage, pageSize: 10 }).then(res => {
        this.archieveList = res.data.data.archiveList
        window.scrollTo(0, 0)
      })
    },
    handleLeft() {
      this.timeLeft += 280
    },
    hangdleRight() {
      this.timeLeft -= 280
    },
    routeChange() {
      window.scrollTo({
        top: 0
      })
      const url = this.$route.query.url === '110' ? '124' : this.$route.query.url
      const urlType = this.$route.query.urlType
      const num = this.menuList.findIndex(item => {
        return item.url === url
      })
      this.menuActive = num < 0 ? 0 : num
      this.menuTitle = this.menuList[this.menuActive].name
      this.getInfo(url, urlType)
    },
    menuSelect(item, index) {
      this.menuActive = index
      this.menuTitle = item.name
      this.$router.push({ path: '/xljrEnteringXinLi', query: { url: item.url, urlType: item.urlType }})
    },
    detailArchieve(item) {
      const routeData = this.$router.resolve({
        path: '/archieveDetail', query: { menuId: 145, id: item.id }
      })
      window.open(routeData.href, '_blank')
      // this.$router.push({ path: '/archieveDetail', query: { menuId: 145, id: item.id }})
    },
    init() {
      getMenu({ tenantId: 'xljr' }).then(res => {
        const menu = res.data.data.menuList.find(item => {
          return item.id === 109
        })
        this.menuList = menu.children
        this.routeChange()
      })
    },
    getInfo(url, urlType) {
      getMainContent({ tenantId: 'xljr', url, urlType }).then(res => {
        const obj = res.data.data.contentMainList[0]
        this.contentList = obj.title === '公司简介' ? obj.contentItemTreeList : [obj]
        const time = this.contentList.find(item => {
          return item.flag === '1'
        })
        this.timeLineList = time ? time.children : []
        if (obj.id === 135) {
          getArchiveListByMenuId({ tenantId: 'xljr', menuId: 145, pageNum: this.news.currentPage, pageSize: 10 }).then(res => {
            this.archieveList = res.data.data.archiveList
            this.news.total = res.data.data.total
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.pagination_con{
  display: flex;
  margin: 20px 0;
  .el-pagination{
    margin: 0 auto;
  }
}
.introduction{
  background: #fff;
}
.culture{
  padding: 90px 0;
  .head{
    margin-bottom: 90px;
  }
  .substance{
    position: relative;
    .vision{
      padding: 40px;
      position: absolute;
      right: 60px;
      top: 50%;
      transform: translateY(-50%);
      background: rgba(255, 255, 255, 0.8);
      color: #333;
      .title{
        font-size: 32px;
        margin-bottom: 10px;
      }
      .detail{
        width: 310px;
        line-height: 30px;
        font-size: 14px;
      }
    }
  }
}
.comp_culture{
  .home_content{
    img{
      width: 100%;
      margin-top: 40px;
    }
    ul{
      margin: 40px 0;
      li{
        &:last-child{
          p{
            &:first-child{
              margin-top: 55px;
              margin-bottom: 25px;
            }
          }
        }
        p{
          font-size: 18px;
          .title{
            font-weight: 700;
          }
        }
      }
    }
  }
}
.develop{
  background-image: url(../assets/images/company/develop_bg.jpg);
  background-size: cover;
  padding: 137px 0;
  .head .home_title,.home_sub{
    color: #fff;
  }
  .home_content{
    position: relative;
  }
  .head{
    margin-bottom: 40px;
  }
  .arrow{
    cursor: pointer;
    width: 42px;
    height: 42px;
    position: absolute;
    z-index: 1;
    top: calc(50% + 42px);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .left_arrow{
    left: 0;
    background-image: url(../../statics/images/left.png);
  }
  .right_arrow{
    right: 0;
    background-image: url(../../statics/images/right.png);
  }
  .timeline_module{
    width: 1120px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    .timeline{
      transition: all 0.3s;
      position: relative;
      display: flex;
      .item{
        position: relative;
        flex-grow:0;
        flex-shrink:0;
        width: 280px;
        color: #fff;
        &:nth-child(2n){
          .label{
            .vertical_line{
              bottom: 0;
              top: unset;
            }
          }
        }
        .top,.bottom{
          height: 185px;
          display: flex;
          align-items: center;
          text-align: center;
          font-size: 14px;
          .b_title{
            margin-bottom: 20px;
          }
          .date{
            position: relative;
            font-size: 24px;
            font-weight: 700;
          }
        }
        .top{
          margin-bottom: 20px;
          flex-direction: column-reverse;
          .date{
            bottom: -5px;
          }
        }
        .bottom{
          margin-top: 20px;
          flex-direction: column;
          .date{
            top: -5px;
          }
        }
        .label{
          height: 112px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;
          .line{
            height: 1px;
            width: 100%;
            background-color: #fff;
            position: absolute;
          }
          .point{
            width: 16px;
            height: 16px;
            background-image: url(../../statics/images/point.png);
            background-size: cover;
            background-repeat: no-repeat;
          }
          .vertical_line{
            width: 1px;
            height: 40px;
            background-color: #fff;
            position: absolute;
            top: 0px;
          }
        }

      }

    }
  }
}
.comp_clut{
  width: 1200px;
  margin: 0 auto;
  padding: 100px;
}
.news{
  width: 1200px;
  margin: 0 auto;
  ul{
    padding: 20px 0;
    li{
      cursor: pointer;
      background-color: #fff;
      display: flex;
      padding: 25px;
      margin-bottom: 25px;
      img{
        width: 200px;
        height: 120px;
        background-color: #333;
        margin-right: 30px;
        flex-shrink: 0;
      }
      .info{
        width: 100%;
      }
      .head{
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        .title{
          font-size: 20px;
          color: #000;
        }
        .date{
          color: #999;
          font-size: 18px;
        }
      }
      .remark{
        color: #999;
        font-size: 18px;
      }
    }
  }
}
.architecture{
  width: 1200px;
  margin: 0 auto;
  img{
    width: 100%;
  }
}
.aptitude{
  width: 1200px;
  margin: 0 auto;
  ul{
    li{
      padding: 20px 0;
      .aptitude_d_ul{
        margin-left: 10px;
        .title{
          font-size: 18px;
          font-weight: 700;
        }
        .sub{
          font-size: 18px;
        }
      }
      .title{
        font-size: 24px;
      }
      .sub{
        margin: 30px 0 30px 0;
        font-size: 14px;
      }
      img{
        max-width: 100%;
        margin: 0 auto;
      }
      div{
        text-align: center;
      }
    }
  }
}
.project{
  width: 1200px;
  margin: 0 auto;
  &>ul{
    padding: 50px 150px;
    &>li{
      display: flex;
      margin-bottom: 50px;
      .year{
        color: #155FAE;
        font-size: 36px;
        margin-right: 20px;
        width: 81px;
      }
      .year_list{
        flex-grow: 1;
        li{
          margin-bottom: 10px;
          font-size: 14px;
          display: flex;
          .month{
            flex: 0 0 40px;
          }
        }
      }
    }
  }
}
</style>
